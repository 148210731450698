/* LoggedOutPage.css */
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  scroll-behavior: smooth;
}
.hero {
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
  color: white;
  padding: 60px 5%;
  text-align: center;
}
.hero h1 {
  font-size: 36px;
  margin-bottom: 20px;
}
.hero p {
  font-size: 18px;
  max-width: 600px;
  margin: 0 auto 30px;
  line-height: 1.6;
}
.content {
  max-width: 800px;
  margin: 40px auto;
  padding: 0 20px;
}
.section {
  margin-bottom: 40px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}
.section.visible {
  opacity: 1;
  transform: translateY(0);
}
.btn-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}
.choice-btn {
  background-color: #4e54c8;
  color: white;
  border: none;
  padding: 15px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  text-align: center;
}
.choice-btn:hover {
  background-color: #3a3f9e;
}
.choice-btn.selected {
  background-color: #2ecc71;
}
.result {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}
.calendar {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}
.calendar-event {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.plan-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}
.plan-card:hover {
  transform: translateY(-5px);
}
.plan-card h3 {
  margin-top: 0;
  color: #4e54c8;
}
.next-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  display: block;
  margin: 20px auto;
}
.next-btn:hover {
  background-color: #45a049;
}
.advanced-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
}
